.chat_content {
  width: 300px;
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  overflow: hidden;

  .chat_content_info {
    padding: 0 20px 10px 20px;
  }

}