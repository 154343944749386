@import "src/assets/scss/variables";

.button-wrapper {
  border-radius: $button-primary-radius !important;

  &.ant-btn-default {
    height: 37px;
    min-width: 90px;
    color: #597EF7FF;
    border: 1px solid $button-primary-color;
  }

  &.ant-btn-primary {
    background-color: $button-primary-color;

    &:disabled {
      color: #fff;
      pointer-events: none;
      opacity: 0.5;
      background-color: $button-primary-color;
    }

    border: none;
    height: 37px;
    min-width: 90px;

    &:focus {
      background-color: #40a9ff;
    }

    &:hover {
      background-color: #40a9ff;

      &:focus {
        background-color: #40a9ff;
      }
    }

  }
}



