@import 'src/assets/scss/variables';

.ant_des_search_container {
  .ant_des_search {
    input {
      border-top-left-radius: $sp-xs;
      border-bottom-left-radius: $sp-xs;
    }
  }

  .ant-btn-icon-only {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}


