@import "src/assets/scss/variables";

.upload_drag {
  & .ant-upload-drag {
    border-radius: $sp-xs !important;
    overflow: hidden;
  }

  .ant-upload.ant-upload-drag .ant-upload {
    border-radius: 10px !important;
    padding: 0;
  }

  box-sizing: border-box;
  height: 225px;

  .img-content {
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
  }

  .ant-upload.ant-upload-disabled {
    cursor: default;
  }
}