
.ant-drawer-content {
  overflow: initial;
}

.overview_main_container {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon_content {
      background-color: #fff;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      height: 100%;
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      left: -80px;
      width: 80px;
      z-index: 99;
      font-size: 20px;

      span {
        cursor: pointer;
      }
    }

    .left_fr {
      display: flex;
      align-items: center;

      h2 {
        margin: 0;
        font-size: 1.4rem;
        font-weight: 400;
      }
      .course_info_content {
        div {
          display: flex;
          align-items: center;
        }

        h5 {
          margin: 0 0 0 5px
        }
      }
    }

    .right_fr {
      .ant-form-item {
        margin: 0; //hishenq hanenq
      }
      .link_full_detail {
        display: flex;
        padding: 4px 8px;
        border-radius: 5px;
        align-items: center;
        font-weight: 400;
        background-color: rgba(89, 126, 247, 0.05);

        span {
          margin-left: 5px;
        }
      }
    }
  }

  .overview_body_content {
    .edit_button_content {
      gap: 10px;
      display: grid;
      align-items: center;
      grid-template-columns: auto 1fr auto;
      .left_text_general_details {
        color: #597EF7;
      }
      .divider {
        height: 1px;
        background-color: rgba(227, 227, 227, 0.85);
        display: block;
      }
    }

    .edit_general_detail {
      .social_sharing_image_content {
        max-width: 450px;
      }

      margin: 10px 0;

    }
  }
}