@import "src/assets/scss/variables";

.header-main-content {
  .right-bar {
    ul {
      display: grid;
      gap: $sp-l;
      grid-template-columns: 1fr auto auto;

      .user-profile-container {
        display: flex;
        align-items: center;
        cursor: pointer;

        .user_profile {
          background-color: rgba(237, 237, 246, 0.68);
          height: $sp-3xl;
          border-radius: $sp-2xs;
          display: flex;
          align-items: center;
          padding: $sp-2xs $sp-3xm;

          .avatar {
            margin-left: $sp-3xm;
          }
        }
      }
    }
  }
}

