@import "src/assets/scss/mixins";

.linkWrapper {
  color: $link-color;

  &.underline {
    text-decoration: underline;
  }

  &.overline {
    text-decoration: overline;
  }

  &.medium {
    @include fontWeight(medium);
  }

  &.regular {
    @include fontWeight(regular);
  }

  &.bold {
    @include fontWeight(bold);
  }

  &.small {
    @include link(small)
  }

  &.base {
    @include link(base)
  }
}
