@import "../../assets/scss/variables";

.site-layout {
  .site-header-layout {
    padding: 0 30px;
    background-color: #FAFBFF;

    .header-main-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  //global class names
  .main-content {
    padding: 20px;
  }

  .padding-left-space {
    padding-left: 20px;
  }

  .form-layout-max-width {
    max-width: 580px;
  }

  //------------------
}

.table-action-buttons-container {
  .ant-btn {
    border: none;
    background: none;

    span {
      font-size: 20px;
    }
  }

  .ant-btn-icon-only.ant-btn-lg {
    width: 38px;
    height: 38px;
  }

  .default-action-button {
    color: $button-default-color;

    &:hover {
      background-color: rgba(89, 126, 247, 0.06);
    }
  }

  .delete-action-button {
    color: #FF4D4F;
    background-color: rgba(255, 77, 79, 0.08);

    &:hover {
      background-color: rgba(255, 77, 79, 0.15);

      span {
        color: #e93c3e;
      }
    }

    &:focus {
      color: #e93c3e;
    }

    &:active {
      color: #e93c3e;
    }
  }

  .success-action-button {
    background-color: rgba(82, 196, 26, 0.08);
    color: #52C41A;

    &:hover {
      background-color: rgba(82, 196, 26, 0.15);
    }
  }
}