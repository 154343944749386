@import "src/assets/scss/variables";

.base_react_editor_container {
  .ql-editor {
    height: 300px;
  }

  .disabled_editor {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
  }

  .ql-editor.ql-blank::before {
    color: #bebcbc;
    font-style: normal;
    font-size: $sp-xm;
  }

  .ql-container.ql-snow {
    border-bottom-left-radius: $sp-xs;
    border-bottom-right-radius: $sp-xs;
  }

  .ql-toolbar.ql-snow {
    border-top-left-radius: $sp-xs;
    border-top-right-radius: $sp-xs;
  }

  .ql-container {
    height: 150px;
    overflow: hidden;
  }

  .editor_alt_rich_text {
    overflow-wrap: anywhere;
  }

}