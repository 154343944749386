@import "src/assets/scss/variables";

.main_input_number {
  width: 100%;
  padding: 2px 4px;
  border-radius: $input-radius !important;

  &.readonly_number_input {
    border-color: transparent;
    pointer-events: none;
  }

}
