.textEllipsisBlock {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.textEllipsisInline {
  display: inline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-popover-inner {
  border-radius: 10px !important;
}

.ant-dropdown-menu {
  border-radius: 10px !important;
}

.pointerCursor {
  cursor: pointer;
}