@import "src/assets/scss/variables";


@mixin statusStyle($color, $bgColor, $borderColor) {
  color: $color;
  background-color: $bgColor;
  border: 1px solid $borderColor;
}


//----------------------- font weight -----------------------------
@mixin fontWeight($weight) {
  @if ($weight == regular) {
    font-weight: $font-weight-regular;
  }
  @if ($weight == medium) {
    font-weight: $font-weight-medium
  }

  @if ($weight == bold) {
    font-weight: $font-weight-bold;
  }
}

//------------------------ heading --------------------------------
@mixin heading($level) {
  @if ($level == h1) {
    font-size: $font-size-heading-1;
    line-height: $line-height-heading-1;
    letter-spacing: $letter-spacing-heading-1;

  } @else if ($level == h2) {
    font-size: $font-size-heading-2;
    line-height: $line-height-heading-2;
    letter-spacing: $letter-spacing-heading-2;

  } @else if ($level == h3) {
    font-size: $font-size-heading-3;
    line-height: $line-height-heading-3;
    letter-spacing: $letter-spacing-heading-3;

  } @else if ($level == h4) {
    font-size: $font-size-heading-4;
    line-height: $line-height-heading-4;
    letter-spacing: $letter-spacing-heading-4;
  }
}

//----------------------- body text -----------------------------

@mixin bodyTextTypes($size) {
  @if ($size == extraSmall) {
    font-size: $font-size-extra-small;
    line-height: $line-height-extra-small;
    letter-spacing: $letter-spacing-extra-small;

  } @else if ($size == small) {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-small;

  } @else if ($size == base) {
    font-size: $font-size-base;
    line-height: $line-height-base;
    letter-spacing: $letter-spacing-base;

  } @else if ($size == large) {
    font-size: $font-size-large;
    line-height: $line-height-large;
    letter-spacing: $letter-spacing-large;

  } @else if ($size == extraLarge) {
    font-size: $font-size-extra-large;
    line-height: $line-height-extra-large;
    letter-spacing: $letter-spacing-extra-large;
  }
}

//----------------------- link text -----------------------------

@mixin link($size) {
  @if ($size == small) {
    font-size: $link-small;
    line-height: $link-line-height-small;
    letter-spacing: $link-letter-spacing-small;

  } @else if ($size == base) {
    font-size: $link-base;
    line-height: $link-line-height-base;
    letter-spacing: $link-letter-spacing-base;
  }
}