@import "src/assets/scss/variables";

.rich_text_container {
  list-style: initial;

  li {
    list-style: initial;
  }

  ul, ol {
    padding-left: $sp-2xl !important;
    margin-bottom: $sp-m;
  }
}