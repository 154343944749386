@import 'src/assets/scss/variables';

.table-container {
  overflow: hidden;
  margin-top: $sp-l;
  border-radius: $sp-3xm;
  border: 1px solid #e5e1e1;

  .ant-pagination {
    padding-right: $sp-3xm;
  }

  .ant-form-item {
    margin: 0;
  }
}