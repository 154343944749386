@import "src/assets/scss/mixins";

.heading_wrapper {
  &.hide_margin {
    margin: 0 !important;
  }

  &.medium {
    @include fontWeight(medium);
  }

  &.regular {
    @include fontWeight(regular);
  }

  &.bold {
    @include fontWeight(bold);
  }

  &.h1 {
    @include heading(h1);
  }

  &.h2 {
    @include heading(h2);
  }

  &.h3 {
    @include heading(h3);
  }

  &.h3 {
    @include heading(h4);
  }
}