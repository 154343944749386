@import "src/assets/scss/variables";

.and-card-wrapper {
  border-radius: $sp-m !important;

  .cardTitle {
    width: 200px;
  }

  .ant-card-body {
    padding: 14px;
    overflow: hidden;
  }

  .ant-image {
    width: 100%;
  }

  .img-container {
    overflow: hidden;
    width: 100%;
    border-radius: 16px;

    img {
      width: 100%;
      height: 180px;
    }
  }
}

.grid-item {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0.5em;
}
