//color
$header-height: 76px;
$button-primary-color: #5754DB;
$button-primary-radius: 5px;
$button-color: #5754DB;
$button-default-color: #597ef7;
$container-radius: 10px;
$button-primary-color: #597EF7;
$input-radius: 4px;
$main-color: #5754DB;
$link-color: #597EF7;


$font-family: 'Inter';
$font-weight-regular: normal;
$font-weight-medium: 600;
$font-weight-bold: bold;

//Spacing
$sp-3xs: 2px;
$sp-2xs: 4px;
$sp-xs: 6px;
$sp-s: 8px;
$sp-3xm: 10px;
$sp-2xm: 12px;
$sp-xm: 14px;
$sp-m: 16px;
$sp-l: 20px;
$sp-xl: 24px;
$sp-2xl: 32px;
$sp-3xl: 40px;
$sp-4xl: 48px;
$sp-5xl: 56px;
$sp-6xl: 64px;
$sp-7xl: 72px;
$sp-8xl: 80px;
$sp-9xl: 88px;
$sp-10xl: 96px;
$sp-11xl: 104px;
$sp-12xl: 112px;
$sp-13xl: 120px;
$sp-14xl: 128px;
$sp-15xl: 136px;
$sp-16xl: 144px;

//Regular (Body Text Style)

$font-size-base: 16px;
$font-size-small: $font-size-base - 2px;
$font-size-extra-small: $font-size-small - 2px;
$font-size-large: $font-size-base + 4px;
$font-size-extra-large: $font-size-large + 4px;

$line-height-extra-small: 20px;
$line-height-small: 22px;
$line-height-base: 24px;
$line-height-large: 28px;
$line-height-extra-large: 32px;

$letter-spacing-base: 2%;
$letter-spacing-extra-small: $letter-spacing-base;
$letter-spacing-small: $letter-spacing-base;
$letter-spacing-large: $letter-spacing-base;
$letter-spacing-extra-large: 1.6%;


//Regular (Link Styles)

$link-small: 14px;
$link-base: 16px;

$link-line-height-small: 22px;
$link-line-height-base: 24px;

$link-letter-spacing-base: 2%;
$link-letter-spacing-small: 2%;


//Medium   (Subheading Text Styles, Body Text Styles)
$font-size-subheading-1: 32px;
$font-size-subheading-2: 28px;
$font-size-subheading-3: 24px;
$font-size-subheading-4: 20px;

$line-height-subheading-1: 40px;
$line-height-subheading-2: 36px;
$line-height-subheading-3: 32px;
$line-height-subheading-4: 28px;

$letter-spacing-subheading-1: 1.2%;
$letter-spacing-subheading-2: 1.6%;
$letter-spacing-subheading-3: 1.6%;
$letter-spacing-subheading-4: 2%;

// Bold  (Heading Text Styles, Body Text Styles)

$font-size-heading-1: 32px;
$font-size-heading-2: 28px;
$font-size-heading-3: 24px;
$font-size-heading-4: 20px;

$line-height-heading-1: 44px;
$line-height-heading-2: 40px;
$line-height-heading-3: 36px;
$line-height-heading-4: 28px;

$letter-spacing-heading-1: 1.2%;
$letter-spacing-heading-2: 1.2%;
$letter-spacing-heading-3: 1.6%;
$letter-spacing-heading-4: 2%;