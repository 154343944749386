@import 'src/assets/scss/variables';

.text_input {
  border-radius: $sp-xs !important;

  &.readonly_text_input {
    border-color: transparent;
    pointer-events: none;
  }

  &.keep_read_only_style {
    border: 1px solid #d9d9d9;
  }
}